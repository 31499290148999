import {
  Box,
  Heading,
  Button,
  Text
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from "react";
import Layout from '../components/Layout';
import Wrapper from '../components/atoms/Wrapper';

const NotFoundPage = ({ location }) => {
  return (
    <Layout 
      location={location}
      seo={{
        meta_title: 'Page Not Found'
      }}>
        <Box>
          <Wrapper>
            <Box textAlign="center" mb="10">
              <Heading size="md" mb="6">
                404: Page Not Found
              </Heading>
              <Text textTransform="uppercase">Sorry, but the page you have requested could not be found.</Text>
            </Box>
            <Button as={GatsbyLink} to="/" variant="primary" w="100%">
              Go Home
            </Button>
          </Wrapper>
        </Box>
    </Layout>
  );
}

export default NotFoundPage
